import { Box, Text } from "@chakra-ui/react";

interface MetricsInfoProps {
	label: string;
	primary: number;
	secondary?: number;
	color?: string;
	highlightPrimary?: boolean;
	highlightSecondary?: boolean;
	topBar?: boolean;
}

const MetricsInfo: React.FC<MetricsInfoProps> = ({
	label,
	primary,
	secondary,
	color = "grey",
	highlightPrimary = false,
	highlightSecondary = false,
	topBar = false,
}) => (
	<Box
		display="flex"
		alignItems="center"
		justifyContent="space-between"
		width="100%"
		mt={1}
	>
		<Text fontSize={topBar ? "lg" : "xs"} color="grey" fontWeight="medium" lineHeight="normal">
			{label}
		</Text>
		<Text fontSize="xs" lineHeight={1} display="inline" >
			<Text
				as="span"
				fontWeight={topBar ? "bold" : "medium"}
				lineHeight={"normal"}
				fontSize={topBar ? "3xl" : "xs"}
				color={highlightPrimary && primary > 0 ? "red" : color}
			>
				{primary}
			</Text>
			{secondary !== undefined && (
				<Text
					as="span"
					fontWeight={"medium"}
					lineHeight={"normal"}
					fontSize={"xs"}
					color={highlightSecondary && secondary > 0 ? "red" : color}
				>
					/{secondary}
				</Text>
			)}
		</Text>
	</Box>
);

export default MetricsInfo;
