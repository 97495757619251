import {
  HStack,
  PopoverTrigger,
  Popover,
  VStack,
  Icon,
  PopoverContent,
  Badge,
  Box,
  Text,
  IconButton,
  Button,
  PopoverBody,
  PopoverHeader,
  NumberInput,
  NumberInputField,
  OrderedList,
  ListItem,
  Link,
  useDisclosure,
} from "@chakra-ui/react";
import { TaxiAlert } from "@mui/icons-material";
import { BiCart, BiChevronDown, BiChevronUp, BiInfinite } from "react-icons/bi";
import { FiAlertTriangle, FiDollarSign } from "react-icons/fi";
import {
  formatDate,
  formatTime,
  findLatestETAWithGivenTime,
  formatInDateMonthYearUTC,
} from "../../../utils/dateResolvers";
import {
  epochPlatformEtaSeconds,
  getSupplierStateColor,
  globalOrderStatus,
} from "../enums";
import GarrazCopyText from "./GaraazCopyText";
import GaraazGeneralPopover from "./GaraazGeneralPopover";
import GaraazPopOverContainerStack from "./GaraazPopOverContainerStack";
import useOrdersStore from "../store/useOrdersStore";
import {
  AllocatedTo,
  finalOrcaStates,
  startOrcaStates,
} from "./GaraazOrderComponent";
import { useGetOrderMediaConfig } from "../../PlaceOrderTest/queryHooks";
import {
  useGetBrandsInfo,
  useGetCustomerAllSubscriptions,
} from "../queryHooks";
import { useEffect, useMemo, useState } from "react";
import services from "../services";
import GaraazCountDownClockV2 from "./GaraazCountDownClockV2";
import { GiReturnArrow } from "react-icons/gi";
import { AiOutlineStop } from "react-icons/ai";
import { removeUnderScoreWithUpperCase } from "../../../utils/removeUnderscore";
import useNotification from "../../../utils/useNotification";
import { FaTruckLoading } from "react-icons/fa";
import { FcShipped, FcInTransit } from "react-icons/fc";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { BiDollarCircle } from "react-icons/bi";
import { FaCarSide } from "react-icons/fa";
import paths from "../../../utils/paths";
import { NavLink } from "react-router-dom";
import { EngagementMode } from "../enums";
import GaraazGeneralPopoverV2 from "./GaraazGeneralPopverV2";

type GaraazOrderComponentHeaderProps = {
  expandBody: () => void;
  orderStatus: any;
  isBodyVisible: boolean;
  orderData: any;
  etaMissed: boolean;
  setEtaMissed: React.Dispatch<React.SetStateAction<boolean>>;
  activesupplierOrderStatus: AllocatedTo;
  findBrandName: (brandId: string) => any;
  transactionType: any;
  getSupplierBusinessName: (supplierId: string) => any;
  isCustomerOrderFreezed: boolean;
  getSupplierBranchName: (supplierId: any, branchId: any) => any;
};

const GaraazOrderComponentHeader: React.FC<GaraazOrderComponentHeaderProps> = ({
  expandBody,
  etaMissed,
  setEtaMissed,
  orderStatus,
  orderData,
  activesupplierOrderStatus,
  findBrandName,
  transactionType,
  getSupplierBusinessName,
  isBodyVisible,
  isCustomerOrderFreezed,
  getSupplierBranchName,
}) => {
  const toast = useNotification();
  const orderEta = useOrdersStore((state) => state.orderEtas[orderData._id]);
  const orderItems = useOrdersStore((state) => state.orderItems[orderData._id]);
  const { data: mobileConfigs } = useGetOrderMediaConfig();
  const { data: allSubscriptions } = useGetCustomerAllSubscriptions(
    orderData.customer._id
  );

  const [customerSupplierAssociations, setCustomerSupplierAssociations] =
    useState<{}[]>([]);

  const { data: allBrands } = useGetBrandsInfo();
  const setScrollOrderId = useOrdersStore((state) => state.setScrollOrderId);
  const etaProvideClockValue0 = Math.floor(
    new Date(orderData.orderDate).getTime() / 1000
  );
  const etaProvideClockValue100 =
    Math.floor(new Date(orderData.orderDate).getTime() / 1000) +
    mobileConfigs?.threshold_giving_eta_to_customer_mins * 60;

  let orderNotoShow = orderData.orderNo.split("-");
  orderNotoShow = orderNotoShow[orderNotoShow.length - 1];

  const customerDetailsToCopy = `
     ${orderData?.customer?.name}
     ${orderData?.shipmentAddress?.addressLine1}
     ${orderData?.shipmentAddress?.addressLine2}
     ${orderData?.shipmentAddress?.city}, ${
    orderData?.shipmentAddress?.state
  }, ${orderData?.shipmentAddress?.pinCode}
     ${orderData?.shipmentAddress?.country}
     +${orderData.customer?.mobileNumber?.slice(
       0,
       2
     )} ${orderData.customer?.mobileNumber?.slice(2)}
     ${orderData?.customer?.code}
  `;

  const [etaProvideMissed, setEtaProvideMissed] = useState(false);
  const [PPDAmount, setPPDAmount] = useState(orderData?.PPDAmount ?? "");
  const latestETA = findLatestETAWithGivenTime(orderEta)?.latestTime;

  useEffect(() => {
    let customerSupplierAssociationsTemp: {}[] = [];

    orderData?.customer?.supplierAssociations?.map(
      (association: any, index: number) => {
        let customerSupplierAssociationObject = {
          "Supplier Name": association?.supplier?.name
            ? association?.supplier?.businessName
            : "-",
          "Branch Name": association?.supplier?.branches
            ? association?.supplier?.branches[0]?.branchName
            : "-",

          "Branch Code": association?.supplier?.branches
            ? association?.supplier?.branches[0]?.branchCode
            : "-",
          "Supplier Code": association?.supplierCode
            ? association.supplierCode
            : "-",
        };
        customerSupplierAssociationsTemp.push(
          customerSupplierAssociationObject
        );
      }
    );
    setCustomerSupplierAssociations(customerSupplierAssociationsTemp);
    setPPDAmount(orderData?.PPDAmount ?? "");
    setEtaProvideMissed(false);
  }, [orderData]);

  const uniqueBrands = useMemo(() => {
    const uniqueBrands: any = new Set(
      orderItems?.map((item: any) => item.brandId)
    );
    return Array.from(uniqueBrands).filter((item) => item);
  }, [orderItems, orderData]);

  function preparePopverAddressObject(shipmentAddress: any) {
    return {
      addressLine1: orderData?.shipmentAddress?.addressLine1,
      addressLine2: orderData?.shipmentAddress?.addressLine2,
      addressLine3:
        orderData?.shipmentAddress?.city +
        ", " +
        orderData?.shipmentAddress?.state +
        ", " +
        orderData?.shipmentAddress?.pinCode,
      country: orderData?.shipmentAddress?.country,
      "Mobile Number": `+${orderData.customer?.mobileNumber?.slice(
        0,
        2
      )} ${orderData.customer?.mobileNumber?.slice(2)}`,
    };
  }

  function getActiveSubscriptionDetails(
    allSubscriptions: any,
    format: "UI" | "ORIGINAL"
  ) {
    if (!allSubscriptions) return;

    let activeSubscription;
    for (let i = 0; i < allSubscriptions.length; i++) {
      const endDate = new Date(allSubscriptions[i].endDate);
      const startDate = new Date(allSubscriptions[i].startDate);
      if (endDate >= new Date() && startDate <= new Date()) {
        activeSubscription = allSubscriptions[i];
        break;
      }
    }

    if (!activeSubscription) {
      return;
    }

    return format === "UI"
      ? {
          "Start Date": activeSubscription.startDate
            ? formatInDateMonthYearUTC(activeSubscription.startDate)
            : "",
          "End Date": activeSubscription.endDate
            ? formatInDateMonthYearUTC(activeSubscription.endDate)
            : "",
          Amount: "₹" + (activeSubscription.amount ?? ""),
          "Plan Deliveries":
            activeSubscription.plan_deliveries > 1000 ? (
              <BiInfinite></BiInfinite>
            ) : (
              activeSubscription.plan_deliveries
            ),
          "Taken Deliveries": activeSubscription.taken_deliveries ?? "",
        }
      : activeSubscription;
  }

  function getExpiredSubscriptionDetails(
    allSubscriptions: any,
    format: "UI" | "ORIGINAL"
  ) {
    if (!allSubscriptions) return;
    let maxEndDate = new Date("1970/01/01");
    let maxEndDateSubscription;

    for (let i = 0; i < allSubscriptions.length; i++) {
      const endDate = new Date(allSubscriptions[i].endDate);
      if (endDate > maxEndDate && endDate < new Date()) {
        maxEndDate = endDate;
        maxEndDateSubscription = allSubscriptions[i];
      }
    }

    if (!maxEndDateSubscription) {
      return;
    }

    return format === "UI"
      ? {
          "Start Date": maxEndDateSubscription.startDate
            ? formatInDateMonthYearUTC(maxEndDateSubscription.startDate)
            : "",
          "End Date": maxEndDateSubscription.endDate
            ? formatInDateMonthYearUTC(maxEndDateSubscription.endDate)
            : "",
          Amount: "₹" + (maxEndDateSubscription.amount ?? ""),
          "Plan Deliveries":
            maxEndDateSubscription.plan_deliveries > 1000 ? (
              <BiInfinite></BiInfinite>
            ) : (
              maxEndDateSubscription.plan_deliveries
            ),
          "Taken Deliveries": maxEndDateSubscription.taken_deliveries ?? "",
        }
      : maxEndDateSubscription;
  }

  async function handlePPDAmountSubmit() {
    try {
      if (PPDAmount == 0) {
        return;
      }
      const response = await services.updateOrder(orderData._id, {
        PPDAmount: +PPDAmount,
      });
      toast({
        description: "PPD Amount updated successfully",
        status: "success",
      });
    } catch (error) {
      toast({
        description: "Some error occured in Updating PPD Amount",
        status: "error",
      });
    }
  }

  async function onEtaProvideTimeComplete() {
    //These API calls from throwing ETA missed event Are supressed, as they are overloading BE
    // const response = await services.throwETAProvideMissedEvent(orderData._id);
  }

  async function onEtaTimeComplete() {
    //These API calls from throwing ETA missed event Are supressed, as they are overloading BE
    // const response = await services.throwETAMissedEvent(
    //   orderData._id,
    //   findLatestETAWithGivenTime(orderEta).latestGivenTime,
    //   findLatestETAWithGivenTime(orderEta).latestTime
    // );
  }

  const latestETAInSeconds =
    new Date(findLatestETAWithGivenTime(orderEta)?.latestTime).getTime() / 1000;
  const latestETAGivenTimeInSeconds =
    new Date(findLatestETAWithGivenTime(orderEta)?.latestGivenTime).getTime() /
    1000;
  return (
    <HStack
      height={!isBodyVisible ? "60%" : "20%"}
      py={"0.4rem"}
      px={"0.5rem"}
      justifyContent={"space-between"}
      backgroundColor={"#f0f6f6"}
      boxSizing="border-box"
      style={{ margin: 0 }}
      position={"relative"}
    >
      {!startOrcaStates.includes(orderStatus) && (
        <IconButton
          width={"1.2em"}
          height={"1.2em"}
          lineHeight={1}
          minWidth={"none"}
          aria-label=""
          borderRadius={"50%"}
          position={"absolute"}
          bottom={-2}
          left={"50%"}
          onClick={expandBody}
          isDisabled={startOrcaStates.includes(orderStatus)}
          icon={
            !isBodyVisible ? (
              <BiChevronDown></BiChevronDown>
            ) : (
              <BiChevronUp></BiChevronUp>
            )
          }
        ></IconButton>
      )}

      <HStack
        width={"70%"}
        spacing={6}
        height={"100%"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
      >
        <Box height={"100%"} display={"flex"} flexDirection={"column"}>
          <Text fontWeight={"bold"} fontSize={"small"} color={"#1A202C"}>
            {orderData.orderType === "SUBSCRIPTION"
              ? "SUBSCRIPTION ORDER"
              : "ORDER PLACED"}
          </Text>
          <Text fontSize={"xs"}>
            {formatDate(orderData?.orderDate ?? orderData.createdAt)}
          </Text>
          <Text fontSize={"xs"}>
            {formatTime(orderData?.orderDate ?? orderData.createdAt)}
          </Text>
          <Box marginTop={"auto"}>
            {orderStatus === globalOrderStatus.READY_TO_DISPATCH && (
              <Icon fontSize={"xl"}>
                <FaTruckLoading />
              </Icon>
            )}

            {orderStatus === globalOrderStatus.DELIVERED && (
              <GaraazGeneralPopover
                ArekeysVisible={true}
                CopierKeys={[]}
                title="Delivery Info"
                value={[
                  {
                    "Delivery Date": orderData.deliveryInfo?.deliveryDate ?? "",
                    "Delivery Time": orderData.deliveryInfo?.deliveryTime ?? "",
                    "Delivery Person":
                      orderData.deliveryInfo?.deliveryPerson ?? "",
                    "Delivery Remarks":
                      orderData.deliveryInfo?.deliveryRemarks ?? "",
                  },
                ]}
                trigger={"click"}
              >
                <PopoverTrigger>
                  <Icon cursor={"pointer"} fontSize={"xl"}>
                    <FcShipped />
                  </Icon>
                </PopoverTrigger>
              </GaraazGeneralPopover>
            )}

            {orderStatus === globalOrderStatus.DELIVERED && (
              <GaraazGeneralPopover
                ArekeysVisible={true}
                CopierKeys={[]}
                title="Payment Info"
                value={[
                  {
                    "Hard Cash": orderData.paymentInfo?.hardCash ?? "",
                    "Online Payment Amount":
                      orderData.paymentInfo?.onlinePaymentAmount ?? "",
                    "Cheque Amount": orderData.paymentInfo?.chequeAmount ?? "",
                  },
                ]}
                trigger={"click"}
              >
                <PopoverTrigger>
                  <Icon cursor={"pointer"} fontSize={"xl"} ml={2}>
                    <CurrencyRupeeIcon></CurrencyRupeeIcon>
                  </Icon>
                </PopoverTrigger>
              </GaraazGeneralPopover>
            )}
          </Box>
        </Box>
        <Box>
          <Text fontWeight={"bold"} color={"#1A202C"}>
            SHIP TO{" "}
            {isCustomerOrderFreezed && (
              <Badge fontSize={"3xs"} colorScheme="red" height={"fit-content"}>
                BLOCKED
              </Badge>
            )}
          </Text>
          <GaraazGeneralPopoverV2
            ArekeysVisible={[false, true]}
            title={orderData?.customer?.name}
            value={[
              [preparePopverAddressObject(orderData?.shipmentAddress)],
              customerSupplierAssociations,
            ]}
            CopierKeys={["Mobile Number", "Supplier Code"]}
            titleIcon={
              <>
                <GarrazCopyText
                  text={customerDetailsToCopy}
                  popOverMessage="Customer Details Copied"
                ></GarrazCopyText>
              </>
            }
            subTitles={["Address", "Associations"]}
          >
            <PopoverTrigger>
              <Text
                fontWeight={"bold"}
                textTransform={"uppercase"}
                color="teal.600"
                cursor={"pointer"}
                fontSize={"xs"}
                display={"flex"}
                alignItems={"center"}
              >
                {orderData.customer?.name}

                <BiChevronDown
                  style={{ display: "inline", fontSize: "1.5em" }}
                />
              </Text>
            </PopoverTrigger>
          </GaraazGeneralPopoverV2>
          <Text fontSize={"3xs"}>{orderData?.customer?.type}</Text>
          <HStack alignItems={"center"}>
            <Text fontSize={"xs"}>{orderData?.customer?.code}</Text>
            <GarrazCopyText
              popOverMessage="Garaaz Code Copied"
              text={orderData?.customer?.code}
            />
          </HStack>
        </Box>

        <Box cursor={"pointer"}>
          <Popover trigger="hover">
            <PopoverTrigger>
              <VStack alignItems={"flex-start"} spacing={0}>
                {latestETAInSeconds === epochPlatformEtaSeconds ? (
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"column"}
                  >
                    <Text fontWeight={"bold"} color={"#1A202C"}>
                      ETA
                    </Text>
                    <Icon fontSize={"2xl"}>
                      {" "}
                      <BiInfinite></BiInfinite>
                    </Icon>
                  </Box>
                ) : (
                  <>
                    <Box display={"flex"} alignItems={"center"}>
                      {(etaMissed || finalOrcaStates.includes(orderStatus)) && (
                        <Text fontWeight={"bold"} color={"#1A202C"}>
                          ETA
                        </Text>
                      )}
                      {etaMissed && !finalOrcaStates.includes(orderStatus) && (
                        <Icon color={"red"} ml={1} mb={1}>
                          <TaxiAlert />
                        </Icon>
                      )}
                    </Box>

                    {!orderEta.length &&
                      !startOrcaStates.includes(orderStatus) &&
                      !finalOrcaStates.includes(orderStatus) && (
                        <Box display={"flex"}>
                          {!etaProvideMissed && (
                            <GaraazCountDownClockV2
                              componentSize="medium"
                              value0={etaProvideClockValue0}
                              value100={etaProvideClockValue100}
                              titleLabel="P.ETA"
                              onCompleteTime={onEtaProvideTimeComplete}
                              isCompleted={etaProvideMissed}
                              setIsCompleted={setEtaProvideMissed}
                            ></GaraazCountDownClockV2>
                          )}

                          {etaProvideMissed && (
                            <HStack spacing={1}>
                              <FiAlertTriangle
                                color="red"
                                style={{ marginLeft: "2px" }}
                              />
                              <Text>ETA Missed</Text>
                            </HStack>
                          )}
                        </Box>
                      )}

                    {!!orderEta.length &&
                      !startOrcaStates.includes(orderStatus) &&
                      !finalOrcaStates.includes(orderStatus) &&
                      !etaMissed && (
                        <Box display={"flex"}>
                          <GaraazCountDownClockV2
                            value0={latestETAGivenTimeInSeconds}
                            value100={latestETAInSeconds}
                            componentSize="medium"
                            titleLabel="ETA"
                            onCompleteTime={onEtaTimeComplete}
                            isCompleted={etaMissed}
                            setIsCompleted={setEtaMissed}
                          ></GaraazCountDownClockV2>
                        </Box>
                      )}

                    {(etaMissed || finalOrcaStates.includes(orderStatus)) && (
                      <Box display={"flex"} alignItems={"flex-end"}>
                        <Text
                          fontSize={"xs"}
                          color={`${
                            !finalOrcaStates.includes(orderStatus)
                              ? "red"
                              : "black"
                          }`}
                        >
                          {orderEta.length ? formatTime(latestETA) : null}
                        </Text>
                      </Box>
                    )}
                  </>
                )}
              </VStack>
            </PopoverTrigger>
            <PopoverContent p="0.7rem">
              <Text
                fontSize={"medium"}
                fontWeight={"bold"}
                marginBottom={"5px"}
                textTransform={"uppercase"}
              >
                ETA
              </Text>
              {orderEta?.map((etaObj: any, index: any) => (
                <HStack spacing={1} key={index}>
                  <Box alignSelf={"flex-start"}>
                    <Text>{String(index + 1)}.</Text>
                  </Box>
                  <GaraazPopOverContainerStack
                    key={index}
                    etaObj={etaObj}
                    isLast={index === orderEta.length - 1}
                  />
                </HStack>
              ))}
            </PopoverContent>
          </Popover>
        </Box>
        {!!activesupplierOrderStatus && (
          <Popover trigger="hover">
            <PopoverTrigger>
              <Box cursor={"pointer"}>
                <Text
                  fontWeight={"bold"}
                  fontSize={"small"}
                  color={"#1A202C"}
                  display={"flex"}
                  alignItems={"center"}
                >
                  SUPPLIER STATUS
                  <BiChevronDown
                    style={{ display: "inline", fontSize: "1.5em" }}
                  />
                </Text>
                <Text
                  fontWeight={"bold"}
                  textTransform={"uppercase"}
                  color="teal.600"
                  cursor={"pointer"}
                  fontSize={"xs"}
                  display={"flex"}
                >
                  {getSupplierBusinessName(
                    activesupplierOrderStatus.supplierId
                  )}
                </Text>
                {activesupplierOrderStatus.branchId && (
                  <Text fontSize={"3xs"}>
                    {getSupplierBranchName(
                      activesupplierOrderStatus.supplierId,
                      activesupplierOrderStatus.branchId
                    )}
                  </Text>
                )}

                <Badge
                  backgroundColor={
                    getSupplierStateColor(
                      activesupplierOrderStatus.orderStatus as any
                    )?.color
                  }
                  color={
                    getSupplierStateColor(
                      activesupplierOrderStatus.orderStatus as any
                    )?.textColor
                  }
                >
                  {removeUnderScoreWithUpperCase(
                    activesupplierOrderStatus.orderStatus
                  )}
                </Badge>
              </Box>
            </PopoverTrigger>
            <PopoverContent padding={3}>
              <OrderedList>
                {orderData.allocatedTo.map((item: any, index: number) => {
                  return (
                    <ListItem key={index}>
                      <Box _notFirst={{ marginTop: "4" }}>
                        <Box
                          color={"#1A202C"}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          <Text fontSize={"smaller"}>
                            {" "}
                            {getSupplierBusinessName(item.supplierId)}
                          </Text>
                        </Box>
                        {item.branchId && (
                          <Box>
                            <Text fontSize={"2xs"}>
                              {getSupplierBranchName(
                                item.supplierId,
                                item.branchId
                              )}
                            </Text>
                          </Box>
                        )}
                        <Badge
                          backgroundColor={
                            getSupplierStateColor(item.orderStatus as any)
                              ?.color
                          }
                          color={
                            getSupplierStateColor(item.orderStatus as any)
                              ?.textColor
                          }
                        >
                          {removeUnderScoreWithUpperCase(item.orderStatus)}
                        </Badge>
                      </Box>
                    </ListItem>
                  );
                })}
              </OrderedList>
            </PopoverContent>
          </Popover>
        )}
      </HStack>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        height={"100%"}
        width={"36%"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignContent={"flex-end"}
          marginLeft={"auto"}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            textAlign={"end"}
            marginLeft={"auto"}
          >
            {orderData.orderType === "REGULAR" && (
              <Icon mr={1} fontSize={"xl"} fontWeight={"bold"}>
                <BiCart></BiCart>
              </Icon>
            )}
            {orderData.orderType === "RETURN" && (
              <Icon mt={1}>
                <GiReturnArrow />
              </Icon>
            )}
            {orderData.orderType === "SUBSCRIPTION" && (
              <Icon mr={1} fontSize={"xl"}>
                <BiDollarCircle></BiDollarCircle>
              </Icon>
            )}
            <Text>{`ORDER # `} </Text>

            <Text
              fontSize={"12px"}
              marginLeft={"3px"}
              textAlign={"end"}
              color={"#1A202C"}
            >
              {orderData.orderType === "RETURN" ? "RT-" : ""}
              {orderNotoShow}
            </Text>

            <GarrazCopyText
              popOverMessage="Order Id Copied"
              text={orderData.orderNo}
            />
          </Box>
        </Box>

        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          width={"100%"}
          marginTop={"auto"}
          alignItems={"flex-end"}
        >
          {!!allBrands &&
            !!uniqueBrands &&
            uniqueBrands.map((item: any, index: number) => {
              return (
                <Badge
                  key={index}
                  backgroundColor={"white"}
                  fontSize={"xx-small"}
                  outline={"1px solid #42bfdd"}
                  ml={2}
                >
                  {findBrandName(item)}
                </Badge>
              );
            })}
          {transactionType && (
            <Badge
              backgroundColor={"white"}
              fontSize={"xx-small"}
              outline={"1px solid green"}
              ml={2}
            >
              {transactionType === "Facilitation" ? "F" : "T"}
            </Badge>
          )}

          {orderData.orderType === "REGULAR" && (
            <Badge
              fontSize={"xx-small"}
              height={"fit-content"}
              outline={"0.1px solid grey"}
              ml={2}
            >
              {getActiveSubscriptionDetails(allSubscriptions, "ORIGINAL")
                ?.plan_deliveries >
              getActiveSubscriptionDetails(allSubscriptions, "ORIGINAL")
                ?.taken_deliveries ? (
                "FREE"
              ) : (
                <Popover>
                  {({ isOpen, onClose }) => (
                    <>
                      <PopoverTrigger>
                        <Text fontSize={"xx-small"} cursor={"pointer"}>
                          PPD
                        </Text>
                      </PopoverTrigger>
                      <PopoverContent maxWidth={"30em"}>
                        <PopoverHeader>
                          <Text>PPD Amount</Text>
                        </PopoverHeader>
                        <PopoverBody>
                          <VStack alignItems={"flex-end"}>
                            <NumberInput
                              width={"100%"}
                              id={`ppd-amount-${orderData.orderNo}`}
                              value={PPDAmount}
                              onChange={(e) => {
                                if (+e >= 0) {
                                  setPPDAmount(e);
                                }
                              }}
                              size={"sm"}
                              placeholder="Enter PPD amount to be collected"
                            >
                              <NumberInputField />
                            </NumberInput>
                            <Button
                              colorScheme="blue"
                              size={"xs"}
                              onClick={() => {
                                handlePPDAmountSubmit();
                                onClose();
                              }}
                              isDisabled={PPDAmount === "" || PPDAmount == 0}
                            >
                              Confirm
                            </Button>
                          </VStack>
                        </PopoverBody>
                      </PopoverContent>
                    </>
                  )}
                </Popover>
              )}
            </Badge>
          )}
          <Badge
            ml={2}
            backgroundColor={`${
              orderData?.customer?.engagementMode === EngagementMode.PLATFORM
                ? "white"
                : orderData?.customer?.engagementMode === EngagementMode.EXPIRED
                ? "red.500"
                : "#FFD601"
            }`}
            color={
              orderData?.customer?.engagementMode === EngagementMode.EXPIRED
                ? "white"
                : "black"
            }
            outline={
              orderData?.customer?.engagementMode === EngagementMode.PLATFORM
                ? "1px solid #FFD601"
                : "none"
            }
            fontSize={"xx-small"}
            height={"fit-content"}
          >
            {orderData?.customer?.engagementMode ===
              EngagementMode.SUBSCRIBED ||
            orderData?.customer?.engagementMode === EngagementMode.EXPIRED ? (
              <GaraazGeneralPopover
                ArekeysVisible={true}
                title={
                  orderData?.customer?.engagementMode ===
                  EngagementMode.SUBSCRIBED
                    ? "Subscription Details"
                    : "Expired Subscription Details"
                }
                value={
                  orderData?.customer?.engagementMode ===
                  EngagementMode.SUBSCRIBED
                    ? ([
                        getActiveSubscriptionDetails(allSubscriptions, "UI"),
                      ].filter(Boolean) as any)
                    : ([
                        getExpiredSubscriptionDetails(allSubscriptions, "UI"),
                      ].filter(Boolean) as any)
                }
                CopierKeys={[]}
                titleIcon={null}
              >
                <PopoverTrigger>
                  <Text
                    fontWeight="bold"
                    textTransform={"capitalize"}
                    cursor={"pointer"}
                    fontSize={"1em"}
                    display={"inline"}
                    justifyContent={"center"}
                  >
                    {orderData?.customer?.engagementMode}
                  </Text>
                </PopoverTrigger>
              </GaraazGeneralPopover>
            ) : (
              orderData?.customer?.engagementMode
            )}
          </Badge>
          <Link
            as={NavLink}
            to={paths.cars}
            state={{
              orderId: orderData._id,
              orderNo: orderData.orderNo,
              scrollPosition:
                window.scrollY || document.documentElement.scrollTop,
            }}
            ml={2}
            display={"flex"}
            alignItems={"center"}
            onClick={() => {
              setScrollOrderId(orderData._id);
            }}
          >
            <Icon as={FaCarSide}></Icon>
          </Link>
          {orderStatus === globalOrderStatus.CUSTOMER_HOLD && (
            <Icon as={AiOutlineStop} ml={2} color={"red"}></Icon>
          )}
        </Box>
      </Box>
    </HStack>
  );
};

export default GaraazOrderComponentHeader;
