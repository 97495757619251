import {
  Button,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Box,
  Text,
  VStack,
  Radio,
  Checkbox,
  RadioGroup,
  CheckboxGroup,
  Input,
  HStack,
  Select,
} from "@chakra-ui/react";
import { dateTodayApiFormat } from "../../../utils/dateResolvers";
import GaraazSearch from "../../../components/GaraazSearch";
import endpoints from "../../../utils/endpoints";
import TextLabels from "../../PlaceOrderTest/enums";
import { getAuthAxios } from "../../../config/axios";
import globalConstant from "../../../utils/constants";
import useFiltersStore from "../store/useFiltersStore";
import useOrdersStore from "../store/useOrdersStore";
import { useEffect, useState } from "react";
import GaraazCheckBoxTitle from "./GaraazCheckBox";
import { CustomerType } from "../enums";
import { useGetAllOrderHandlers } from "../queryHooks";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  setOrderStartIndex: React.Dispatch<React.SetStateAction<number>>;
  setIsFiltersChanged: React.Dispatch<React.SetStateAction<boolean>>;
  refetchAllOrders: () => void;
};
const SideFilterTab = (props: Props) => {
  const {
    isOpen,
    onClose,
    setOrderStartIndex,
    setIsFiltersChanged,
    refetchAllOrders,
  } = props;

  const authJSON = localStorage.getItem(globalConstant.AUTH_TOKEN) ?? "";
  const authAxios = getAuthAxios(process.env.REACT_APP_BASE_URL, authJSON);
  const { data: allOrderHandlers } = useGetAllOrderHandlers();

  const setFilterObject = useFiltersStore((state) => state.setFilterObject);
  const setQueryOffset = useOrdersStore((state) => state.setQueryOffset);
  const filterObject = useFiltersStore((state) => state.filterObject);

  const orderStatuses = [
    "NEW",
    "ACK",
    "CUSTOMER_HOLD",
    "ALLOCATED",
    "CBO",
    "CANCELLED",
    "DELIVERED",
    "READY_TO_DISPATCH",
    "RETURN_REQUESTED",
    "CUSTOMER_RETURNED",
    "CUSTOMER_RETURN_REJECTED",
    "SUPPLIER_RETURNED",
    "SUPPLIER_RETURN_REJECTED",

    "ACCEPTED",
    "REJECTED",
  ];

  const supplierOrderStatuses = [
    "NEW",
    "ACK",
    "CBO",
    "CANCELLED",
    "READY",
    "REVOKED",
    "NONE",
    "RETURN_REQUESTED",
    "ACCEPTED",
    "REJECTED",
  ];

  const orderTypes = ["REGULAR", "RETURN", "SUBSCRIPTION"];
  const transactionTypes = ['Facilitation', 'Trading'];
  const [localFilterObject, setLocalFilterObject] = useState<{
    limit: number;
    orderStatus: string;
    supplierOrderStatus: string;
    customerType: string;
    orderHandler: string;
    filterOrderNo: string;
    filterOrderNoCheckBoxValue: boolean;
    customerId: string;
    customerName: string;
    orderType: string;
    transactionType : string;
    startDate: string;
    endDate: string;
  }>({
    ...filterObject,
    startDate: filterObject.startDate ?? dateTodayApiFormat,
    endDate: filterObject.endDate ?? dateTodayApiFormat,
  });

  const [customerId, setCustomerId] = useState("");

  useEffect(() => {
    setLocalFilterObject({
      ...localFilterObject,
      customerId: customerId,
    });
  }, [customerId]);

  function handleFilterApply() {
    setFilterObject({ ...localFilterObject, customerId: customerId });
    setQueryOffset(0);
    setOrderStartIndex(1);
    setIsFiltersChanged(true);
    onClose();
  }

  function handleClearFilters() {
    setLocalFilterObject({
      ...localFilterObject,
      orderStatus: ["", ...orderStatuses].join("&orderStatus="),
      supplierOrderStatus: ["", ...supplierOrderStatuses].join(
        "&supplierOrderStatus="
      ),
      customerType: "",
      orderHandler: "ANY",
      filterOrderNo: "",
      filterOrderNoCheckBoxValue: false,
      customerId: "",
      customerName: "",
      orderType: ["", ...orderTypes].join("&orderType="),
      transactionType : ["", ...transactionTypes].join("&transactionType="),
      startDate: dateTodayApiFormat,
      endDate: dateTodayApiFormat,
    });
  }

  function handleSingleClickOnTransactionTypeCheckbox(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    let tempArray = localFilterObject.transactionType?.split("&transactionType=")?.slice(1);
    e.target.checked
      ? tempArray?.push(e.target.value)
      : tempArray?.splice(tempArray?.indexOf(e.target.value), 1);
    tempArray?.unshift("");
    let tempString = tempArray?.join("&transactionType=");
  
    setLocalFilterObject({
      ...localFilterObject,
      transactionType: tempString,
    });
  }
  function handleSingleStatusCheckBoxClick(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    let tempArray = localFilterObject.orderStatus
      .split("&orderStatus=")
      .slice(1);
    e.target.checked
      ? tempArray.push(e.target.value)
      : tempArray.splice(tempArray.indexOf(e.target.value), 1);
    tempArray.unshift("");
    let tempString = tempArray.join("&orderStatus=");
    setLocalFilterObject({
      ...localFilterObject,
      orderStatus: tempString,
    });
  }

  function handleSingleTypeCheckBoxClick(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    let tempArray = localFilterObject.orderType?.split("&orderType=")?.slice(1);
    e.target.checked
      ? tempArray?.push(e.target.value)
      : tempArray?.splice(tempArray?.indexOf(e.target.value), 1);
    tempArray?.unshift("");
    let tempString = tempArray?.join("&orderType=");
    setLocalFilterObject({
      ...localFilterObject,
      orderType: tempString,
    });
  }

  function handleSingleSupplierOrderStatusCheckBoxClick(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    let tempArray = localFilterObject.supplierOrderStatus
      .split("&supplierOrderStatus=")
      .slice(1);
    e.target.checked
      ? tempArray.push(e.target.value)
      : tempArray.splice(tempArray.indexOf(e.target.value), 1);
    tempArray.unshift("");
    let tempString = tempArray.join("&supplierOrderStatus=");
    setLocalFilterObject({
      ...localFilterObject,
      supplierOrderStatus: tempString,
    });
  }

  const handleClearCustomer = () => {
    setLocalFilterObject({
      ...localFilterObject,
      customerId: "",
      customerName: "",
    });
    setCustomerId("");
  };

  const handleSearchChange = (value: string) => {
    setLocalFilterObject({
      ...localFilterObject,
      customerName: value,
    });
  };

  const onCancelClick = () => {
    setLocalFilterObject(filterObject);
    onClose();
  };

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      onOverlayClick={onCancelClick}
      returnFocusOnClose={false}
    >
      <DrawerContent>
        <DrawerHeader>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            borderBottom={"1px solid lightgrey"}
          >
            <Text height={"fit-content"} fontSize={"x-large"}>
              Filters
            </Text>
            <Text
              fontSize={"small"}
              display={"flex"}
              alignItems={"center"}
              mr={4}
              color={"#4895ef"}
              cursor={"pointer"}
              textDecoration={"underline"}
              onClick={handleClearFilters}
            >
              Reset Filters
            </Text>
          </Box>
        </DrawerHeader>

        <DrawerBody>
          <Box>
            <Box display={"flex"} mb={1} borderBottom={"0.5px solid lightgrey"}>
              <Checkbox
                isChecked={localFilterObject.filterOrderNoCheckBoxValue}
                onChange={(e) => {
                  setLocalFilterObject({
                    ...localFilterObject,
                    filterOrderNoCheckBoxValue: e.target.checked,
                  });
                }}
              ></Checkbox>
              <Text ml="3" fontWeight={"bold"}>
                Order No.
              </Text>
            </Box>
            <Text fontSize={"xx-small"}>Ends with</Text>

            <Input
              type="text"
              size={"sm"}
              value={localFilterObject.filterOrderNo}
              onChange={(e) => {
                setLocalFilterObject({
                  ...localFilterObject,
                  filterOrderNo: e.target.value,
                });
              }}
            ></Input>
            {localFilterObject.filterOrderNo === "" &&
              localFilterObject.filterOrderNoCheckBoxValue && (
                <Text color={"red"} fontSize={"xs"}>
                  Enter Order No.
                </Text>
              )}
          </Box>
          <Box
            display={"flex"}
            width={"100%"}
            flexDirection={"column"}
            height={"100%"}
            opacity={localFilterObject.filterOrderNoCheckBoxValue ? "0.5" : "1"}
          >
            <Box mt={5}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                mb={3}
                borderBottom={"0.5px solid lightgrey"}
              >
                <Text fontWeight={"bold"}>Order Type</Text>
                <Checkbox
                  isDisabled={localFilterObject.filterOrderNoCheckBoxValue}
                  onChange={(e) => {
                    e.target.checked
                      ? setLocalFilterObject({
                          ...localFilterObject,
                          orderType:
                            "&orderType=REGULAR&orderType=RETURN&orderType=SUBSCRIPTION",
                        })
                      : setLocalFilterObject({
                          ...localFilterObject,
                          orderType: "",
                        });
                  }}
                  isChecked={
                    localFilterObject.orderType?.split("&orderType=")?.slice(1)
                      .length === 3
                  }
                />
              </Box>
              <VStack alignItems={"start"}>
                <CheckboxGroup
                  value={localFilterObject.orderType
                    ?.split("&orderType=")
                    ?.slice(1)}
                  isDisabled={localFilterObject.filterOrderNoCheckBoxValue}
                >
                  {orderTypes.map((item, index) => {
                    return (
                      <GaraazCheckBoxTitle
                        key={index}
                        handleChange={handleSingleTypeCheckBoxClick}
                        title={item}
                        checkBoxValue={item.toUpperCase()}
                      ></GaraazCheckBoxTitle>
                    );
                  })}
                </CheckboxGroup>
              </VStack>
            </Box>
            <Box mt={5}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                mb={3}
                borderBottom={"0.5px solid lightgrey"}
              >
                <Text fontWeight={"bold"}>Transaction Type</Text>
                <Checkbox
                  isDisabled={localFilterObject.filterOrderNoCheckBoxValue}
                  onChange={(e) => {
                    e.target.checked
                      ? setLocalFilterObject({
                          ...localFilterObject,
                          transactionType:
                            "&transactionType=Trading&transactionType=Facilitation",
                        })
                      : setLocalFilterObject({
                          ...localFilterObject,
                          transactionType: "",
                        });
                  }}
                  isChecked={
                    localFilterObject.transactionType?.split("&transactionType=")?.slice(1)
                      .length === 2
                  }
                />
              </Box>
              <VStack alignItems={"start"}>
                <CheckboxGroup
                  value={localFilterObject.transactionType
                    ?.split("&transactionType=")
                    ?.slice(1)}
                  isDisabled={localFilterObject.filterOrderNoCheckBoxValue}
                >
                  {transactionTypes.map((item, index) => {
                    return (
                      <GaraazCheckBoxTitle
                        key={index}
                        handleChange={handleSingleClickOnTransactionTypeCheckbox}
                        title={item}
                        checkBoxValue={item}
                      ></GaraazCheckBoxTitle>
                    );
                  })}
                </CheckboxGroup>
              </VStack>
          </Box> 
            <Box mt="4">
              <Text
                fontWeight={"bold"}
                mb={3}
                borderBottom={"0.5px solid lightgrey"}
              >
                Customer
              </Text>

              <RadioGroup
                onChange={(e) => {
                  setLocalFilterObject({
                    ...localFilterObject,
                    customerType:
                      e === CustomerType.SUBSCRIBED
                        ? CustomerType.SUBSCRIBED
                        : e === CustomerType.PLATFORM
                        ? CustomerType.PLATFORM
                        : e === "ANY"
                        ? ""
                        : "",
                    customerId: "",
                    customerName: "",
                  });
                  setCustomerId("");
                }}
                value={
                  localFilterObject.customerType === "customerSearch"
                    ? "customerSearch"
                    : localFilterObject.customerType === CustomerType.SUBSCRIBED
                    ? CustomerType.SUBSCRIBED
                    : localFilterObject?.customerType === CustomerType.PLATFORM
                    ? CustomerType.PLATFORM
                    : "Any"
                }
                isDisabled={localFilterObject.filterOrderNoCheckBoxValue}
              >
                <VStack alignItems={"start"}>
                  <Radio size={"sm"} value="Any">
                    Any
                  </Radio>
                  <Radio size={"sm"} value={CustomerType.PLATFORM}>
                    Platform
                  </Radio>
                  <Radio size={"sm"} value={CustomerType.SUBSCRIBED}>
                    Subscribed
                  </Radio>
                </VStack>
              </RadioGroup>
              <HStack mt="3">
                <Radio
                  size={"sm"}
                  isChecked={
                    localFilterObject.customerType === "customerSearch"
                  }
                  onChange={() => {
                    setLocalFilterObject({
                      ...localFilterObject,
                      customerType: "customerSearch",
                    });
                  }}
                />
                <GaraazSearch
                  placeholder={TextLabels.CUSTOMER_NAME_OR_NUMBER}
                  queryUrl={endpoints.searchXpressCustomer}
                  axios={authAxios}
                  onSelect={(customer: any) => {
                    setCustomerId(customer._id);
                  }}
                  onClear={handleClearCustomer}
                  onChange={handleSearchChange}
                  acceptMobileNumber={true}
                  hideSearchIcon={true}
                  idField="_id"
                  valueField="name"
                  value={localFilterObject.customerName}
                  isDisabled={
                    localFilterObject.filterOrderNoCheckBoxValue ||
                    localFilterObject.customerType !== "customerSearch"
                  }
                  searchListWidth="74%"
                />
              </HStack>
            </Box>
            <Box mt="4">
              <Text
                fontWeight={"bold"}
                mb={3}
                borderBottom={"0.5px solid lightgrey"}
              >
                Date Range
              </Text>
              <Box>
                <HStack justifyContent={"space-between"} alignItems={"center"}>
                  <Text fontSize={"sm"}>Start Date</Text>
                  <Input
                    isDisabled={localFilterObject.filterOrderNoCheckBoxValue}
                    type="date"
                    width={"60%"}
                    value={localFilterObject.startDate}
                    max={dateTodayApiFormat}
                    size={"sm"}
                    onChange={(e) => {
                      setLocalFilterObject({
                        ...localFilterObject,
                        startDate: e.target.value,
                      });
                    }}
                  />
                </HStack>
                <HStack
                  mt="1"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Text fontSize={"sm"}>End Date</Text>
                  <Input
                    isDisabled={localFilterObject.filterOrderNoCheckBoxValue}
                    type="date"
                    width={"60%"}
                    size={"sm"}
                    min={localFilterObject.startDate}
                    max={dateTodayApiFormat}
                    value={localFilterObject.endDate}
                    onChange={(e) => {
                      setLocalFilterObject({
                        ...localFilterObject,
                        endDate: e.target.value,
                      });
                    }}
                  />
                </HStack>
              </Box>
            </Box>

            <Box mt={5}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                mb={3}
                borderBottom={"0.5px solid lightgrey"}
              >
                <Text fontWeight={"bold"}>Status</Text>
                <Checkbox
                  isDisabled={localFilterObject.filterOrderNoCheckBoxValue}
                  onChange={(e) => {
                    e.target.checked
                      ? setLocalFilterObject({
                          ...localFilterObject,
                          orderStatus: ["", ...orderStatuses].join(
                            "&orderStatus="
                          ),
                        })
                      : setLocalFilterObject({
                          ...localFilterObject,
                          orderStatus: "",
                        });
                  }}
                  isChecked={
                    localFilterObject.orderStatus
                      .split("&orderStatus=")
                      .slice(1).length === orderStatuses.length
                  }
                />
              </Box>
              <VStack alignItems={"start"}>
                <CheckboxGroup
                  value={localFilterObject.orderStatus
                    .split("&orderStatus=")
                    .slice(1)}
                  isDisabled={localFilterObject.filterOrderNoCheckBoxValue}
                >
                  {orderStatuses.map((item, index) => {
                    return (
                      <GaraazCheckBoxTitle
                        key={index}
                        handleChange={handleSingleStatusCheckBoxClick}
                        title={
                          item === "ACCEPTED"
                            ? "SUBSCRIPTION ACCEPTED"
                            : item === "REJECTED"
                            ? "SUBSCRIPTION REJECTED"
                            : item
                        }
                        checkBoxValue={item.toUpperCase()}
                      ></GaraazCheckBoxTitle>
                    );
                  })}
                </CheckboxGroup>
              </VStack>
            </Box>
            <Box mt={5}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                mb={3}
                borderBottom={"0.5px solid lightgrey"}
              >
                <Text fontWeight={"bold"}>Supplier Status</Text>
                <Checkbox
                  isDisabled={localFilterObject.filterOrderNoCheckBoxValue}
                  onChange={(e) => {
                    e.target.checked
                      ? setLocalFilterObject({
                          ...localFilterObject,
                          supplierOrderStatus: [
                            "",
                            ...supplierOrderStatuses,
                          ].join("&supplierOrderStatus="),
                        })
                      : setLocalFilterObject({
                          ...localFilterObject,
                          supplierOrderStatus: "",
                        });
                  }}
                  isChecked={
                    localFilterObject.supplierOrderStatus
                      ?.split("&supplierOrderStatus=")
                      .slice(1).length === supplierOrderStatuses.length
                  }
                />
              </Box>
              <VStack alignItems={"start"}>
                <CheckboxGroup
                  value={localFilterObject.supplierOrderStatus
                    ?.split("&supplierOrderStatus=")
                    .slice(1)}
                  isDisabled={localFilterObject.filterOrderNoCheckBoxValue}
                >
                  {supplierOrderStatuses.map((item, index) => {
                    return (
                      <GaraazCheckBoxTitle
                        key={index}
                        handleChange={
                          handleSingleSupplierOrderStatusCheckBoxClick
                        }
                        title={item === "NONE" ? "Not Allocated" : item}
                        checkBoxValue={item.toUpperCase()}
                      ></GaraazCheckBoxTitle>
                    );
                  })}
                </CheckboxGroup>
              </VStack>
            </Box>
            <Box mt={5}>
              <Text
                fontWeight={"bold"}
                mb={3}
                borderBottom={"0.5px solid lightgrey"}
              >
                Order Handler
              </Text>
              <Select
                onChange={(e) => {
                  setLocalFilterObject({
                    ...localFilterObject,
                    orderHandler: e.target.value,
                  });
                }}
                value={localFilterObject.orderHandler}
              >
                <option value="NONE">None</option>
                <option value="ANY">Any</option>
                {allOrderHandlers?.map((item: any, index: number) => {
                  return (
                    <option
                      value={item._id}
                      key={index}
                    >{`${item?.firstName} ${item?.lastName}`}</option>
                  );
                })}
              </Select>
            </Box>
          </Box>
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" onClick={onCancelClick} mr={3}>
            Cancel
          </Button>
          <Button
            variant="outline"
            onClick={handleFilterApply}
            backgroundColor={"rgba(72,149,239,0.8)"}
            color={"white"}
            isDisabled={
              localFilterObject.filterOrderNoCheckBoxValue &&
              localFilterObject.filterOrderNo === ""
            }
            _hover={{ backgroundColor: "rgba(72,149,239,1)" }}
          >
            Apply
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default SideFilterTab;
